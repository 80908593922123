.avatar-style{
    border: 3px solid;
    border-color:#1A446C;
}

.ant-list-item:hover {
transform: scale(1.05);
transition: transform 0.2s ease-in-out;
}

.training-by-category-card {
  width: 160px; 
  height: 260px;
  background: transparent;
  cursor: pointer;
  box-shadow: none !important;
  }
  
  .ant-card.training-by-category-card-cody.css-dev-only-do-not-override-18iikkb {
    box-shadow: none;
  }

  .training-card-img {
    width: 130px;
    height: 170px;
    object-fit: cover;
    border-radius: 8px;
  }

  .ant-card.training-by-category-card.css-dev-only-do-not-override-18iikkb {
    box-shadow: none;
}
.training-card-title{
  color:#1A446C;
  font-size: 14px;
  text-align: left;
  display: list-item;
  width: 130px;
}
.popular-training-card-title{
  color:#1A446C;
  font-weight:'bold';
  font-size:'13px';
  text-align: left;
  display: list-item;
}
.training-card-trainer{
  color:#1A446C;
  font-size: 12px;
  text-align: left;
  display: list-item;
  width: 130px;
}

.popular-training-carausel .ant-carousel.css-dev-only-do-not-override-18iikkb  {
  width: 1050px;
}

.training-by-category-card-carousel   {
  width: 1050px;
}