ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light.css-dev-only-do-not-override-18iikkb {
    border-bottom: 1px solid #A47B5A ;
    color: #1A446C ;
    font-weight: 500 ;
    line-height: 60px;
}

.custom-menu {
    border: none;
    margin-top: 10px;
    margin-left: 50px;
  }
  
  .custom-menu .ant-menu-item-selected {
    font-size: 18px;
    color: #A47B5A ;
  }

  li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    color: #A47B5A;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
  transition: none !important;
}

.custom-card-our-services{
  width: 243px; 
  height: 160px;
  background: transparent;
  margin-left: 10px;
}

.ant-card.custom-card-our-services.css-dev-only-do-not-override-18iikkb {
  box-shadow: none;
}

.ant-card-body-style{
  position: relative;
}

.ant-card-img-service{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ant-card-body {
  padding: 0 !important;
}

.contentStyle {
  height: 180px;
  color: #fff;
  text-align: center;
  background: transparent;
  border-radius: 10;
};

.ant-card-img-investor{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom-card-our-investors{
  width: 110px; 
  height: 160px;
  background: transparent;
  margin-left: 10px;
  box-shadow: none !important;
}

.ant-card.custom-card-our-investors.css-dev-only-do-not-override-18iikkb {
  box-shadow: none;
}






.ant-investor-card-body-style{
  margin-top: 50px;
  position: relative;
}

.ant-card-img-investor{
  width: 130px;
  height: 110px;
  object-fit: cover;
}

.custom-card-web-partner {
  width: 150px; 
  height: 220px;
  background: transparent;
  border: 2px solid #1A446C4D;
}

.ant-card-partner-style {
  position: relative;
}

.ant-card-partner-img {
  width: 100px;
  height: 60px;
  border-radius: 6px;
  margin-top:10px;
  margin-bottom:10px;
  
}

/* .ant-carousel .slick-list{
  height: 400px;
} */

.ant-carousel .slick-dots li{
  width: 56px;
  height: 5px;
}

.newscontentStyle {
  height: 180px;
  color: #fff;
  text-align: center;
  background: transparent;
  border-radius: 10;
};


.web-campaign-card{
  border: 2px solid !important;
  border-color: #1A446C80 !important;
  height: 200px !important;
}

.web-campaign-card-title{
  font-weight: 500;
  font-size: 30px;
  color: #1A446C;
  margin-bottom: 80;
  flex: 1;
  margin-top: 80px;
  overflow: hidden;
  max-width: 50%;
}

.web-campaign-card-img{
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 80px;
  height: 150px;
  width: 300px;
}
.web-campaign-card-card-cody{
  display: flex; 
  flex-direction: row;
  border: none !important;
}

.web-campaign-carousel .ant-carousel.css-dev-only-do-not-override-18iikkb{
  width: 990px !important;
  border: 2px solid !important;
  border-color: #1A446C80 !important;
  height: 200px !important;
  border-radius: 15px;
}

.numberods-carousel .ant-carousel.css-dev-only-do-not-override-18iikkb{
  width: 500px !important;
}

.webnews-carousel-container .ant-carousel.css-dev-only-do-not-override-18iikkb{
  width: 500px !important;
}


.web-campaign-carousel .slick-dots li button::after{
  border: 3px solid #1A446C;
  border-radius: 6px;
  margin-left: 4px;
  opacity: 1;

  margin-top: 50px;
  
}
@media only screen and (max-width: 768px) {
  
  .webnumberandnews{
    display: grid !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .webnews{
    display: inline-block !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 10px;
  }
}

:where(.css-dev-only-do-not-override-18iikkb).ant-menu-horizontal .ant-menu-item,
:where(.css-dev-only-do-not-override-18iikkb).ant-menu-horizontal .ant-menu-submenu-title {
    transition:none !important;
}



.numberods-carousel :where(.css-dev-only-do-not-override-18iikkb).ant-carousel .slick-slide img,
.webnews-carousel-container :where(.css-dev-only-do-not-override-18iikkb).ant-carousel .slick-slide img{
  width: 500px;
  height: 180px;
  object-fit: cover;
  border-radius: 15px;
}