.avatar-style{
    border: 3px solid;
    border-color:#1A446C;
}

.ant-list-item:hover {
transform: scale(1.05);
transition: transform 0.2s ease-in-out;
}

.service-by-category-card {
  width: 160px; 
  height: 210px;
  background: transparent;
  cursor: pointer;
  box-shadow: none !important;
  }
  
  .ant-card.service-by-category-card-cody.css-dev-only-do-not-override-18iikkb {
    box-shadow: none;
  }

  .service-card-img {
    width: 130px;
    height: 170px;
    object-fit: cover;
    border-radius: 8px;
  }

  .ant-card.service-by-category-card.css-dev-only-do-not-override-18iikkb {
    box-shadow: none;
}
.service-card-title{
  color:#1A446C;
  font-size: 14px;
  text-align: left;
  display: list-item;
  width: 130px;
}
.popular-service-card-title{
  color:#1A446C;
  font-size: 14px;
  text-align: left;
  display: list-item;
  width: 130px;
}

.popular-services-carausel .ant-carousel.css-dev-only-do-not-override-18iikkb  {
  width: 1050px;
}

.service-by-category-card-carousel  {
  width: 1050px;
}