.avatar-style{
    border: 3px solid;
    border-color:#1A446C;
}

.ant-list-item:hover {
transform: scale(1.05);
transition: transform 0.2s ease-in-out;
}

.news-by-category-card {
    width: 160px; 
    height: 210px;
    background: transparent;
    cursor: pointer;
    box-shadow: none !important;
  }
  
  .ant-card.news-by-category-card-cody.css-dev-only-do-not-override-18iikkb {
    box-shadow: none;
  }

  .news-card-img {
    width: 130px;
    height: 170px;
    object-fit: cover;
    border-radius: 8px;
  }

.ant-card.news-by-category-card.css-dev-only-do-not-override-18iikkb {
    box-shadow: none;
}
.news-card-title{
    color:#1A446C;
    font-size: 14px;
    text-align: left;
    display: list-item;
    width: 130px;
    overflow: hidden;
}
img.slick-arrow.slick-next {
  width: 20px;
  height: 35px;
}
img.slick-arrow.slick-prev {
  width: 20px;
  height: 35px;
}

.last-news-carausel .ant-carousel.css-dev-only-do-not-override-18iikkb  {
  width: 1050px;
}

.news-by-category-card-carousel {
  width: 1050px;
}

.news-by-category-card-carousel .slick-slide.slick-active.slick-current div div {
  width: 98% !important; 
}

.last-news-card-title{
  color:#1A446C;
  font-size: 14px;
  text-align: left;
  display: list-item;
  width: 400px;
  margin-top: 10px;
}

