.avatar-style{
    border: 3px solid;
    border-color:#1A446C;
}

.ant-list-item:hover {
transform: scale(1.05);
transition: transform 0.2s ease-in-out;
}

.campaign-by-category-card {
  width: 160px; 
  height: 260px;
  background: transparent;
  cursor: pointer;
  }
  
  .ant-card.campaign-by-category-card-cody.css-dev-only-do-not-override-18iikkb {
    box-shadow: none;
  }

  .campaign-card-img {
    width: 130px;
    height: 170px;
    object-fit: cover;
    border-radius: 8px;
  }

  .ant-card.campaign-by-category-card.css-dev-only-do-not-override-18iikkb {
    box-shadow: none;
}
.campaign-card-title{
  color:#1A446C;
  font-size: 14px;
  text-align: left;
  display: list-item;
  width: 130px;
  overflow: hidden;
}

.popular-campaign-card-title{
  color:#1A446C;
  font-size: 14px;
  text-align: left;
  display: list-item;
  width: 130px;
}
.popular-campaign-carausel .ant-carousel.css-dev-only-do-not-override-18iikkb  {
  width: 1050px;
}
.campaign-by-category-carousel  {
  width: 1050px;
}
